<template>
  <div>
    <hs-table
      hover
      responsive
      selectable
      :fields="fields"
      :items="messages"
      select-mode="single"
      @selectedItems="openChannelMessages"
    >
      <template slot="owner.first_name" slot-scope="row">
        <p :class="{ 'font-weight-bold': !row.item.read }" class="m-0 ml-3">{{ row.value }}</p>
      </template>
      <template slot="subject" slot-scope="row">
        <p :class="{ 'font-weight-bold': !row.item.read }" class="m-0 ml-3 truncate">{{ row.value }}</p>
      </template>
      <template slot="actions" slot-scope="row">
        <hs-button
          variant="link"
          @click="changesMessageReadStatus({ channel: row.item, isToggleRead: true })"
          v-b-tooltip.hover.top="
            $t(`messages.views.list.components.messages-table.mask-as-${row.item.read ? 'not-' : ''}seen`)
          "
        >
          <hs-icon variant="regular" :icon="`${!row.item.read ? 'envelope' : 'envelope-open'}`" />
        </hs-button>
      </template>
      <template slot="updated_at" slot-scope="row">
        <span class="m-0">{{ row.value | toTimeAgo }}</span>
      </template>
    </hs-table>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { memberService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { format } from '@/shared/helpers/dates';

export default {
  name: 'MessagesTable',
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  filters: {
    toTimeAgo(timestamp) {
      const date = Date.parse(timestamp);

      if (dayjs(date).isSame(new Date(), 'day')) return dayjs(date).format('HH:mm');
      if (dayjs(date).isSame(new Date(), 'year')) {
        const THIRD_OCCURRENCE = 3;
        const formattedDate = format(date, 'll');
        const getPosition = formattedDate.split(' ', THIRD_OCCURRENCE).join(' ').length;
        return formattedDate.substring(0, getPosition);
      }
      return format(date, 'L');
    },
  },
  data() {
    return {
      fields: [
        { key: 'owner.first_name', label: '' },
        { key: 'subject', label: '' },
        { key: 'actions', label: '' },
        { key: 'updated_at', label: '' },
      ],
    };
  },
  computed: {
    ...mapState('auth', {
      loggedUser: state => state.loggedUser,
      channels: state => state.channels,
    }),
  },
  methods: {
    async changesMessageReadStatus({ channel, isToggleRead = false }) {
      try {
        const member = channel.members.find(member => member.user && member.user.id === this.loggedUser.id);
        member.last_view = dayjs().toJSON();

        if (isToggleRead) {
          member.last_view = channel.read ? null : dayjs().toJSON();
          channel.read = !channel.read;
        }

        await memberService.update({ ...member });
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('messages.views.list.index.toast.error'));
      }
    },
    async openChannelMessages(channel) {
      await this.changesMessageReadStatus({ channel: channel[0] });

      this.$router.push({
        name: 'MessageSubject',
        params: { channelId: channel[0].id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 25vw;
  width: 100%;
}
</style>
